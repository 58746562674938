@import url("https://fonts.googleapis.com/css?family=Nunito:400,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400&display=swap");
.editm-text-cursor {
  cursor: text; }

.edit-mode {
  padding: 20px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999; }
  .edit-mode .edit-mode__controls {
    display: flex;
    align-items: center;
    justify-content: center; }

.edit-mode.hidden {
  display: none; }

.editm-ext-button-wrap {
  position: relative; }

.editm-ext-button {
  pointer-events: all;
  cursor: pointer;
  background-color: #2d3047;
  font-family: "Nunito", sans-serif;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 10px;
  color: #fff;
  border: none;
  border-radius: 10px;
  margin: 0 2.5px;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.79); }
  .editm-ext-button svg {
    margin-left: 10px; }

.editm-ext-button.success {
  background-color: #1b998b; }

.editm-feedback-box--floating.show {
  animation-name: fadeInOut;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-delay: 1s; }

.editm-feedback-box--floating.success {
  background: #1b998b;
  color: #fff; }

.editm-feedback-box--floating {
  max-width: 200px;
  padding: 10px 40px;
  font-size: 20px;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-family: "Nunito", sans-serif;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.79); }

.editm-feedback-box {
  padding: 5px 30px;
  font-family: "Nunito", sans-serif;
  text-align: center;
  margin: 0 2.5px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.79); }

.editm-feedback-box.success {
  background: #1b998b;
  color: #fff; }

.editm-feedback-box.show {
  animation-name: fadeInOut;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-delay: 1s; }

@keyframes fadeInOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translateY(0px); } }

.editm-edited-element {
  background: #fffd82e0 !important;
  color: #2d3047 !important; }



.editm-dialog-box.hidden {
  display: none; }

.editm-dialog-box {
  background: #fff;
  color: #000;
  padding: 20px;
  margin-left: auto;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.79);
  width: max-content;
  cursor: auto; }
  .editm-dialog-box .editm-dialog-box__loading {
    display: flex;
    justify-content: center;
    align-items: center; }
    .editm-dialog-box .editm-dialog-box__loading img {
      margin-right: 10px;
      width: 25px;
      height: 25px; }
    .editm-dialog-box .editm-dialog-box__loading span {
      font-family: "Open Sans", sans-serif; }
  .editm-dialog-box .editm-dialog-box__send-editions .editm-dialog-box__send-editions-title {
    font-family: "Nunito", sans-serif;
    font-weight: bold;
    color: #2d3047;
    text-align: left;
    margin-bottom: 10px;
    margin-left: 5px; }
  .editm-dialog-box .editm-dialog-box__send-editions .editm-dialog-box__copy-link {
    display: flex;
    align-items: center;
    justify-content: center; }
    .editm-dialog-box .editm-dialog-box__send-editions .editm-dialog-box__copy-link .editm-ext-button {
      margin-right: 10px; }
    .editm-dialog-box .editm-dialog-box__send-editions .editm-dialog-box__copy-link .editm-dialog-box__copy-link-text {
      text-overflow: ellipsis;
      width: 140px;
      white-space: nowrap;
      overflow: hidden;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 13px; }
      .editm-hovered {
        color: black !important;
        cursor: text !important;
        background: rgba(110, 110, 230, 0.73) !important;
      }
      
      .editm-content-editable {
        cursor: text !important;
      }
      .edit-mode__cartfuel {
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        color: #2D3047;
        margin: 16px 10px;
        text-align: right; }
        .edit-mode__cartfuel a {
          text-decoration: underline;
          color: inherit;
          pointer-events: all; }
          .edit-mode__cartfuel a:hover {
            color: inherit; }